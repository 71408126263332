<template>
  <div>
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'Reclamation'">
    </breadcrumb-base>

    <transition name="fade">
      <div class="content-center"  v-if="reclaim">
        <div class="vx-col md:w-4/5 w-full mb-base c-center">
          <vx-card :title="reclaim.product + ' - (' + getNumberFormat(reclaim.denomination) + ')'">

            <div class="vx-row" v-if="reclaim">
              <div class="vx-col md:w-1/3">
                <div class="mt-4">
                  <p class="customer-text-heading">{{$t('StatusColor')}}</p>
                  <p class="user-name font-medium truncate">
                    <feather-icon icon="CircleIcon" :svgClasses="['fill-current h-6 w-6', 'text-' + getStatusColor(reclaim.status)]"/>
                  </p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('Cusomer') }}</p>
                  <p class="customer-text">
                    {{ reclaim.customer_id }} - {{ reclaim.organisation_name }}
                  </p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{$t('ReclamationStatus')}}</p>
                  <div class="flex">
                    <vs-chip :color="getStatusColor(reclaim.status)" class="user-status">{{
                        getStatusText(reclaim.status) | title
                      }}
                    </vs-chip>
                  </div>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{$t('ReclaimMessage')}}</p>
                  <p class="customer-text">{{reclaim.clam_message}}</p>
                </div>
              </div>
              <div class="vx-col md:w-1/3">
                <div class="mt-4">
                  <p class="customer-text-heading">{{$t('Batch')}}</p>
                  <p class="customer-text">{{reclaim.batch}}</p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{$t('Pin')}}</p>
                  <p class="customer-text">{{reclaim.pin}}
                  </p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{$t('TransactionDate')}}</p>
                  <p class="customer-text">{{reclaim.transaction_at | dateFormat}}</p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{$t('Remarks')}}</p>
                  <p class="customer-text">{{reclaim.remark || '-'}}</p>
                </div>
              </div>
              <div class="vx-col md:w-1/3">
                <div class="mt-4">
                  <p class="customer-text-heading">{{$t('RequestedUser')}}</p>
                  <p class="customer-text">{{reclaim.created_by}}</p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{$t('LastUpdated')}}</p>
                  <p class="customer-text">{{reclaim.updated | dateFormat}}
                  </p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{$t('Created')}}</p>
                  <p class="customer-text">{{reclaim.created | dateFormat}}</p>
                </div>
              </div>
            </div>

          </vx-card>
        </div>
      </div>
    </transition>

    <vx-modal v-if="showRejectMessageModal" @close="showRejectMessageModal = false" component-class="modal-container-fit">
      <template slot="header"></template>
      <template slot="body" >
      <div class="p-2 flex flex-centered">
        <vx-col>
          <h3>{{ $t('RejectReason') }}</h3>
          <div class="p-2"></div>
          <vs-textarea :label="$t('Reason')"
                       autofocus
                       v-model="remarks"/>

          <div class="vs-row flex justify-center mt-3" >
            <vs-button class="ml-2 mt-3" color="primary" @click="updateReject()">{{ $t('Submit') }}</vs-button>
            <vs-button class="ml-4 mt-3" color="danger" @click="showRejectMessageModal = false">{{ $t('Close') }}</vs-button>
          </div>
        </vx-col>
      </div>
      </template>
    </vx-modal>

  </div>
</template>

<script>

import VxModal from '@/layouts/components/custom/VxModal'

export default {
  name: 'SellerReclaimDetails',
  components: {
    VxModal,
  },
  props: {
    reclaimId: {
      type: String | Number,
    },
  },
  computed: {

  },
  data() {
    return {
      remarks: '',
      showRejectMessageModal: false,
      reclaim: null,
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Reclamations', i18n: 'Reclamations', url: '/seller-reclaim-requests' },
        { title: 'Reclamation', i18n: 'Reclamation', active: true },
      ],
    }
  },
  methods: {
    getStatusText(status) {
      // 0 - new, 1 - in-progress, 2 - sold, 3 - issue credit note, 5 - reject
      if (status === 0) return this.$t('InReview')
      if (status === 1) return this.$t('InProgress')
      if (status === 2) return this.$t('InProgress')
      if (status === 3) return this.$t('IssueCreditNote')
      if (status === 5) return this.$t('Reject')
      if (status === 6) return this.$t('IssueCreditNote')
      return ''
    },
    getStatusColor(status) {
      // 0 - new, 1 - in-progress, 2 - sold, 3 - issue credit note, 5 - reject
      if (status === 0) return 'primary'
      if (status === 1) return 'warning'
      if (status === 2) return 'warning'
      if (status === 3) return 'success'
      if (status === 5) return 'danger'
      if (status === 6) return 'success'
      return ''
    },
    getNumberFormat(value) {
      if (value == null || value == undefined || isNaN(value)) return '-'
      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      })
      return formatter.format(value)
    },
    async updateReject() {
      const payload = {
        update_reclamation_pin: JSON.stringify({
          reclamation_id: this.reclaim.reclamation_id,
          status: 5,
          remarks: this.remarks
        }),
      }
      this.$vs.loading()
      const data = await this.$store.dispatch('reclamation/fetchReclaimRequests', {payload}).catch((error) => {
        this.$vs.loading.close()
        this.showRejectMessageModal = false
        this.remarks = ""
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.response.statusText,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return false
      })
      if (data) {
        this.showRejectMessageModal = false
        this.remarks = ""
        this.$vs.loading.close()
        this.fetchReclaim()
        return true
      }
      this.showRejectMessageModal = false
      this.$vs.loading.close()
      this.$vs.notify({
        position: 'top-center',
        title: 'Error',
        text: 'Unknown error',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger',
      })
    },
    async updateReclaimStatus(status) {
      const payload = {
        update_reclamation_pin: JSON.stringify({
          reclamation_id: this.reclaim.reclamation_id,
          status: status
        }),
      }
      this.$vs.loading()
      const data = await this.$store.dispatch('reclamation/fetchReclaimRequests', {payload}).catch((error) => {
        this.$vs.loading.close()
        this.showRejectMessageModal = false
        this.remarks = ""
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.response.statusText,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return false
      })
      if (data) {
        this.showRejectMessageModal = false
        this.remarks = ""
        this.$vs.loading.close()
        this.fetchReclaim()
        return true
      }
      this.showRejectMessageModal = false
      this.$vs.loading.close()
      this.$vs.notify({
        position: 'top-center',
        title: 'Error',
        text: 'Unknown error',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger',
      })
    },
    fetchReclaim() {
      this.$vs.loading()
      return this.$store.dispatch('reclamation/fetchSingleReclaim', this.reclaimId)
        .then((data) => {
          this.reclaim = data
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  mounted() {
    this.fetchReclaim()
  },
}
</script>

<style>
  .customer-text-heading {
    color: #00000085;
  }
  .theme-dark .customer-text-heading {
    color: white !important;
  }
  .customer-text {
    font-size: 20px;
    font-weight: 500;
    word-break: break-all;
  }
  .c-center {
    margin: auto;
  }

  .p-type {
    padding: 0 1rem;
    font-size: 10px;
  }

  .p-type-select {
    padding: 0 0 0 1rem;
  }

  .vx-card__title > h4 {
    font-weight: bold !important;
  }

  .upload-btn-wrapper {
    margin: 1rem;
    position: relative;
    display: flex;
  }

  .upload-contents {
    border: 1px dashed  #00000040;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
    color: #2C2C2C;
    background-color: white;
    padding: 30px 30px;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    margin: auto;
  }

  .upload-btn-wrapper-denomination {
    position: absolute;
  }

  .upload-contents-denomination {
    padding: 5px;
  }

  .upload-btn-wrapper input[type=file] {
    position: absolute;
    margin: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
  }

  .p-image-holder {
    position: relative;
    display: flex;
  }

  .p-image {
    width: 90%;
    object-fit: cover;
    max-height: 200px;
    margin: 1rem;
  }

  .p-delete-button {
    position: absolute;
    float: right;
    right: 0;
    margin: 0.5rem;
  }

  .p-image-denomination {
    width: 50%;
  }
</style>
